<template>
  <div>
    <div class="px-4 pt-4 w-100 d-flex align-center justify-space-between">
      <div>
          <v-form class="d-flex align-center" v-model="formValid" ref="form" lazy-validation @submit.prevent="getUserDetailsApi()">
            <div class="px-2 fsize14 ">
              <div class="pb-1">User ID</div>
              <v-text-field outlined dense placeholder="Enter the UserId" v-model="userID" :rules="userIdRules"></v-text-field>
            </div>

            <div class="px-2 d-flex justify-end mb-2 mt-2">
              <v-btn type="submit" :loading="getLoader" :disabled="getLoader" height="40" color="primary" depressed class="text-capitalize fsize14">Submit</v-btn>
            </div>

            <div class="px-2 d-flex justify-end mb-2 ml-10 mt-2">
              <v-btn type="button" height="40" color="primary" depressed class="text-capitalize fsize14" @click="addUser()">+ Add User</v-btn>
            </div>
          </v-form>

        <div class="border rounded my-4" v-if="getUserDetails.length">
        <v-simple-table outline class="w-full border">
          <thead>
              <tr>
                  <th class="fsize13 text-center">User Name</th>
                  <th class="fsize13 text-center">E-Mail</th>
                  <th class="fsize13 text-center">Enabled</th>
                  <th class="fsize13 text-center">Email Verified</th>
                  <th class="fsize13 text-center">First Name</th>
                  <th class="fsize13 text-center">Last Name</th>
                  <th class="fsize13 text-center">Mobile</th>
                  <th class="fsize13 text-center">PAN</th>
                  <th class="fsize13 text-center">OMS</th>
                  <th class="fsize13 text-center">Action</th>
              </tr>
            </thead>
            <tbody class="text-sm">
              <tr v-for="(i,id) in getUserDetails" :key="id" >
                <td class="border-r fsize14 text-center">{{ i.username ? i.username : '' }}</td>
                <td class="border-r fsize14 text-center ">{{ i.email ? i.email : '' }}</td>
                <td class="border-r fsize14 text-center">{{ i.enabled  }}</td>
                <td class="border-r fsize14 text-center">{{ i.emailVerified }}</td>
                <td class="border-r fsize14 text-center">{{ i.firstName ? i.firstName : '' }}</td>
                <td class="border-r fsize14 text-center">{{ i.lastName }}</td>
                <td class="border-r fsize14 text-center">{{ i.attributes && i.attributes.mobile[0] ? i.attributes.mobile[0] : '' }}</td>
                <td class="border-r fsize14 text-center">{{ i.attributes && i.attributes.pan[0] ? i.attributes.pan[0] : '' }}</td>
                <td class="border-r fsize14 text-center">{{ i.attributes && i.attributes.oms[0] ? i.attributes.oms[0] : '' }}</td>
                <td class="border-r fsize14">
                    <v-btn depressed outlined class="text-blue-400 cursor-pointer fsize12 text-capitalize" height="20px" @click="updateUser(i)" >Edit</v-btn>
                </td>
              </tr>
            </tbody>
        </v-simple-table>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <style>
.snoWidth {
  width: 25%;
}
.dateWidth {
  width: 75%;
}

</style> -->
<script >
import { mapGetters } from "vuex"
const editSvg = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mx-auto">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
`
export default {
  data() { 
    return {
      tabs: ["Login Based", "User Based"],
      userID: '',
      submitted : false,
      userIdRules: [(v) => !!v || "userID is required"],
      formValid: true,
    }
  },
  computed: {
    ...mapGetters('user', ['getUserDetails','getLoader'])
  },
  methods: {
    getUserDetailsApi() {
      if (this.$refs.form.validate()) {
        let json = {
          "userId": this.userID
        }
        this.$store.dispatch('user/getUserDetails', json)
      }
    },
    addUser() {
      this.$router.push('/addOrEditUser')
    },
    updateUser(data) {
      localStorage.setItem('currentEditData', JSON.stringify(data))
      this.$router.push('/addOrEditUser?edit=true')
    }
  },
  created() {
    this.$store.commit('user/setUserDetails', [])
  },
}
</script>
